import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { RouterOutlet, provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
// Import the library
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { AdminModule } from './admin/admin.module';
import { WebpageModule } from './webpage/webpage.module';
import { ComponentesModule } from './componentes/componentes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { requestInterceptorInterceptor } from './interceptors/request-interceptor.interceptor';
import { corsInterceptor } from './interceptors/cors.interceptor';
//import { loaderProcessInterceptor } from './servicios/loader-process.interceptor';

//provideClientHydration(withHttpTransferCacheOptions({includePostRequests: true}))
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),provideHttpClient(withFetch(), withInterceptorsFromDi(),withInterceptors([
    requestInterceptorInterceptor,corsInterceptor])),provideAnimations(),provideNgxStripe(environment.STRIPE_API),
    importProvidersFrom([CommonModule,RouterOutlet,ComponentesModule, AdminModule, WebpageModule,ReactiveFormsModule])
   ]
   //
};
