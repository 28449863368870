import { HttpInterceptorFn } from '@angular/common/http';

export const requestInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem('token'); 
  if (token) {
      const authReq = req.clone({
        setHeaders: {Authorization: `Token ${token}`},
      });
      return next(authReq);
    }

    // If there's no token, just pass the original request
    return next(req);
};
