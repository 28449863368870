import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DnsRoutingModule } from './dns-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DnsRoutingModule
  ]
})
export class DnsModule { }
