import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { ComponentesModule } from '../componentes/componentes.module';
import { AcomponentesModule } from '../acomponentes/acomponentes.module';
import { CdnModule } from './cdn/cdn.module';
import { DnsModule } from './dns/dns.module';
import { EmailModule } from './email/email.module';
import { DedicatedModule } from './dedicated/dedicated.module';
import { VpsModule } from './vps/vps.module';
import { LoadBalancerModule } from './load-balancer/load-balancer.module';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ComponentesModule,
    AcomponentesModule,
    DnsModule,
    EmailModule,
    DedicatedModule,
    VpsModule,
    LoadBalancerModule,
    CdnModule
  ]
})
export class AdminModule { }
