import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadBalancerRoutingModule } from './load-balancer-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LoadBalancerRoutingModule
  ]
})
export class LoadBalancerModule { }
