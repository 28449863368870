import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ComponentesModule } from './componentes/componentes.module';
import { AdminModule } from './admin/admin.module';
import { WebpageModule } from './webpage/webpage.module';
import {ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,ComponentesModule, AdminModule, WebpageModule,ReactiveFormsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'Networkcode Tech Solution';
}
