import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebpageRoutingModule } from './webpage-routing.module';
import { ComponentesModule } from '../componentes/componentes.module';
import { IndexComponent } from './index/index.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    WebpageRoutingModule,
    ComponentesModule,
    IndexComponent
  ]
})
export class WebpageModule { }
