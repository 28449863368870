import { Routes } from '@angular/router';
import { authGuard } from './interceptors/auth.guard';

export const routes: Routes = [
    { path: "", pathMatch: "full", redirectTo: 'home' },
    { path: "login",loadComponent: () => import('./login/login.component').then(c=> c.LoginComponent)},
    {
        path: "admin",
        loadChildren: () => import('./admin/admin-routing.module')
          .then(m => m.AdminRoutingModule),
        canActivate: [authGuard]
    },
    {
        path: "home",
        loadChildren: () => import('./webpage/webpage-routing.module')
          .then(m => m.WebpageRoutingModule)
    }
];
