import { HttpInterceptorFn } from '@angular/common/http';

export const corsInterceptor: HttpInterceptorFn = (req, next) => {
    
    //'Access-Control-Allow-Origin':'*',
    //'Access-Control-Allow-Methods':'GET, POST, PUT, DELETE, OPTIONS, HEAD',
    //'Allow':'GET, POST, PUT, DELETE, OPTIONS, HEAD'
    
  let request = req.clone(
    {setHeaders: {
        'Content-Type':'application/json'}
    })
  return next(request);
};
